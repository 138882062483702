import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxArchive, faEye, faArrowUpRightFromSquare, faBug, faCircleInfo, faCheck, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { Card, Modal, Pagination, Button, Table, Col, Row, Form, FloatingLabel } from 'react-bootstrap';
import { Cargador } from '../componentes/Cargador';
import Axios from 'axios';
import { urlSyslogApi } from '../recursos/configuracion';
import { obtenerFechaDeTiempo, obtenerHoraDeTiempo } from '../recursos/funcion';
import moment from 'moment';

function Visor() {
  const parametro = useParams();
  const locacion = useLocation();
  const navegar = useNavigate();

  const [pagina, setPagina] = useState(1);

  const [datos, setDatos] = useState([]);
  const [paginas, setPaginas] = useState([]);
  const [paginado, setPaginado] = useState(
    {
      inicio: 0,
      fin: 0
    }
  );

  const [ambiente, setAmbiente] = useState([]);
  const [usuario, setUsuario] = useState([]);
  const [programa, setPrograma] = useState([]);
  const [lado, setLado] = useState([]);
  const [archivo, setArchivo] = useState([]);
  const [funcion, setFuncion] = useState([]);
  const [tiempo, setTiempo] = useState([]);


  const [carga, setCarga] = useState(false);

  const [detalle, setDetalle] = useState(false);

  const [modal, setModal] = useState(false);

  const cerrarModal = () => setModal(false);
  const mostrarModal = () => setModal(true);

  useEffect(() => {
    recuperar();
    cargarLista();
    setInterval(() => {
      recuperar();
    }, 20000);
  }, []);

  useEffect(() => {
    setPagina(parseInt(parametro.pagina));
  }, [locacion]);

  useEffect(() => {
    if (pagina !== 0) {
      recuperar();
    }
  }, [pagina]);

  async function recuperar() {
    setCarga(true);
    let parametros = {};
    if (document.getElementById('cbAmbiente').value !== 'sin filtro') {
      parametros['ambiente'] = document.getElementById('cbAmbiente').value;
    }
    if (document.getElementById('cbUsuario').value !== 'sin filtro') {
      parametros['usuario'] = document.getElementById('cbUsuario').value;
    }
    if (document.getElementById('cbPrograma').value !== 'sin filtro') {
      parametros['programa'] = document.getElementById('cbPrograma').value;
    }
    if (document.getElementById('cbLado').value !== 'sin filtro') {
      parametros['lado'] = document.getElementById('cbLado').value;
    }
    if (document.getElementById('cbArchivo').value !== 'sin filtro') {
      parametros['archivo'] = document.getElementById('cbArchivo').value;
    }
    if (document.getElementById('cbFuncion').value !== 'sin filtro') {
      parametros['funcion'] = document.getElementById('cbFuncion').value;
    }
    if (document.getElementById('cbTipo').value !== 'sin filtro') {
      parametros['tipo'] = document.getElementById('cbTipo').value;
    }
    if (document.getElementById('cbInicio').value !== 'sin filtro') {
      parametros['tiempoInicio'] = moment(document.getElementById('cbInicio').value, "DD-MM-YYYY").format("YYYY-MM-DD");
    }
    if (document.getElementById('cbFin').value !== 'sin filtro') {
      parametros['tiempoFin'] = moment(document.getElementById('cbFin').value, "DD-MM-YYYY").format("YYYY-MM-DD");
    }
    let respuesta = await Axios.put(`${urlSyslogApi}/registros?pagina=${pagina}&intervalo=${parametro.intervalo}`, parametros);
    setDatos(respuesta.data.arrayJson);
    setPaginas(respuesta.data.objetoJson.paginas);
    setPaginado(respuesta.data.objetoJson.paginado);
    setCarga(false);
  }

  async function cargarLista() {
    try {
      let respuesta = await Axios.get(`${urlSyslogApi}/listas`);
      //console.log(respuesta.data.objetoJson);
      setAmbiente(respuesta.data.objetoJson.ambiente);
      setUsuario(respuesta.data.objetoJson.usuario);
      setPrograma(respuesta.data.objetoJson.programa);
      setLado(respuesta.data.objetoJson.lado);
      setArchivo(respuesta.data.objetoJson.archivo);
      setFuncion(respuesta.data.objetoJson.funcion);
      setTiempo(respuesta.data.objetoJson.tiempo);
    } catch (error) {
      console.log(error);
    }
  }

  function atenderCaso(caso) {
    console.log(caso);
  }

  function verDetalle(detalle) {
    console.log(detalle);
    //setDetalle(detalle);
    //mostrarModal();
  }

  function dirigir(paginanueva) {
    if (paginanueva === 0) {

    } else {
      navegar(`/${paginanueva}/${parametro.intervalo}`);
    }
  }

  return (
    <>
      <div className='container-fluid mt-3 mb-3'>
        <div className='row mb-1'>
          <div className='col-md-12'>
            <h1>
              {(carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faBoxArchive} />)}Registros
            </h1>
          </div>
          <div className='col-md-12 mt-2'>

          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <Card border="secondary" className='bg-dark text-white'>
              <Card.Body>
                <div className='row'>
                  <div className='col-md-12 mt-2 mb-2'>
                    <Row>
                      <Col md={4} className='mt-1 mb-1'>
                        <FloatingLabel controlId="cbAmbiente" label="Ambiente" className='text-dark' onChange={() => {
                          recuperar();
                        }}>
                          <Form.Select aria-label="Floating label select example">
                            <option value='sin filtro'>Sin filtro</option>
                            {
                              ambiente.map((item, llave) => (
                                <option value={item.nombre} key={llave}>{item.nombre}</option>
                              ))
                            }
                          </Form.Select>
                        </FloatingLabel>
                      </Col>
                      <Col md={4} className='mt-1 mb-1'>
                        <FloatingLabel controlId="cbUsuario" label="Usuario" className='text-dark' onChange={() => {
                          recuperar();
                        }}>
                          <Form.Select aria-label="Floating label select example">
                            <option value='sin filtro'>Sin filtro</option>
                            {
                              usuario.map((item, llave) => (
                                <option value={item.nombre} key={llave}>{item.nombre}</option>
                              ))
                            }
                          </Form.Select>
                        </FloatingLabel>
                      </Col>
                      <Col md={4} className='mt-1 mb-1'>
                        <FloatingLabel controlId="cbPrograma" label="Programa" className='text-dark' onChange={() => {
                          recuperar();
                        }}>
                          <Form.Select aria-label="Floating label select example">
                            <option value='sin filtro'>Sin filtro</option>
                            {
                              programa.map((item, llave) => (
                                <option value={item.nombre} key={llave}>{item.nombre}</option>
                              ))
                            }
                          </Form.Select>
                        </FloatingLabel>
                      </Col>
                      <Col md={4} className='mt-1 mb-1'>
                        <FloatingLabel controlId="cbLado" label="Lado" className='text-dark' onChange={() => {
                          recuperar();
                        }}>
                          <Form.Select aria-label="Floating label select example">
                            <option value='sin filtro'>Sin filtro</option>
                            {
                              lado.map((item, llave) => (
                                <option value={item.nombre} key={llave}>{item.nombre}</option>
                              ))
                            }
                          </Form.Select>
                        </FloatingLabel>
                      </Col>
                      <Col md={4} className='mt-1 mb-1'>
                        <FloatingLabel controlId="cbArchivo" label="Archivo" className='text-dark' onChange={() => {
                          recuperar();
                        }}>
                          <Form.Select aria-label="Floating label select example">
                            <option value='sin filtro'>Sin filtro</option>
                            {
                              archivo.map((item, llave) => (
                                <option value={item.nombre} key={llave}>{item.nombre}</option>
                              ))
                            }
                          </Form.Select>
                        </FloatingLabel>
                      </Col>
                      <Col md={4} className='mt-1 mb-1'>
                        <FloatingLabel controlId="cbFuncion" label="Función" className='text-dark' onChange={() => {
                          recuperar();
                        }}>
                          <Form.Select aria-label="Floating label select example">
                            <option value='sin filtro'>Sin filtro</option>
                            {
                              funcion.map((item, llave) => (
                                <option value={item.nombre} key={llave}>{item.nombre}</option>
                              ))
                            }
                          </Form.Select>
                        </FloatingLabel>
                      </Col>
                      <Col md={4} className='mt-1 mb-1'>
                        <FloatingLabel controlId="cbTipo" label="Tipo" className='text-dark' onChange={() => {
                          recuperar();
                        }}>
                          <Form.Select aria-label="Floating label select example">
                            <option value='1'>Error</option>
                            <option value='0'>Info</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Col>
                      <Col md={4} className='mt-1 mb-1'>
                        <FloatingLabel controlId="cbInicio" label="Fecha Inicio" className='text-dark' onChange={() => {
                          recuperar();
                        }}>
                          <Form.Select aria-label="Floating label select example">
                            <option value='sin filtro'>Sin filtro</option>
                            {
                              tiempo.map((item, llave) => (
                                <option value={item.nombre} key={llave}>{item.nombre}</option>
                              ))
                            }
                          </Form.Select>
                        </FloatingLabel>
                      </Col>
                      <Col md={4} className='mt-1 mb-1'>
                        <FloatingLabel controlId="cbFin" label="Fecha Fin" className='text-dark' onChange={() => {
                          recuperar();
                        }}>
                          <Form.Select aria-label="Floating label select example">
                            <option value='sin filtro'>Sin filtro</option>
                            {
                              tiempo.map((item, llave) => (
                                <option value={item.nombre} key={llave}>{item.nombre}</option>
                              ))
                            }
                          </Form.Select>
                        </FloatingLabel>
                      </Col>
                    </Row>
                  </div>
                  <div className='col-md-3 mt-2 mb-2'>
                    <Button variant='outline-primary' onClick={() => {
                      recuperar();
                      cargarLista();
                    }}>
                      <FontAwesomeIcon icon={faRefresh} /> Actualizar
                    </Button>
                  </div>
                  <div className='col-md-9 mt-2 mb-2'>
                    <div className="table-responsive">
                      {
                        (paginas.length === 0 ? '' : <Pagination className='d-flex justify-content-end'>
                          {(parseInt(pagina) === 1)}
                          <Pagination.First onClick={() => {
                            dirigir(paginado.inicio);
                          }} disabled={parseInt(pagina) === 1} />
                          <Pagination.Prev onClick={() => {
                            dirigir(parseInt(pagina) - 1);
                          }} disabled={parseInt(pagina) === 1} />
                          {((Math.ceil(pagina / parametro.intervalo) * parametro.intervalo) === (Math.ceil(paginado.inicio / parametro.intervalo) * parametro.intervalo) ? '' : <><Pagination.Item onClick={() => {
                            dirigir(paginado.inicio);
                          }} disabled={(parseInt(pagina) === paginado.inicio)}>{paginado.inicio}</Pagination.Item><Pagination.Ellipsis onClick={() => {
                            dirigir(parseInt(pagina) - 10)
                          }} /></>)}

                          {
                            paginas.map((paginaOpcion, llave) => (
                              <Pagination.Item key={llave} onClick={() => {
                                dirigir((paginaOpcion === parseInt(pagina) ? 0 : paginaOpcion))
                              }} active={paginaOpcion === parseInt(pagina)}>{paginaOpcion}</Pagination.Item>
                            ))
                          }

                          {((Math.ceil(pagina / parametro.intervalo) * parametro.intervalo) === (Math.ceil(paginado.fin / parametro.intervalo) * parametro.intervalo) ? '' : <><Pagination.Ellipsis onClick={() => {
                            dirigir(parseInt(pagina) + 10)
                          }} /><Pagination.Item onClick={() => {
                            dirigir(paginado.fin);
                          }} disabled={(parseInt(pagina) === paginado.fin)}>{paginado.fin}</Pagination.Item></>)}
                          <Pagination.Next onClick={() => {
                            dirigir(parseInt(pagina) + 1);
                          }} disabled={parseInt(pagina) === paginado.fin} />
                          <Pagination.Last onClick={() => {
                            dirigir(paginado.fin);
                          }} disabled={parseInt(pagina) === paginado.fin} />
                        </Pagination>)
                      }
                    </div>
                  </div>
                  <div className='col-md-12 table-responsive'>
                    <Table bordered hover size="lg" className='table-dark'>
                      <thead>
                        <tr>
                          <th>Acción</th>
                          <th>Caso</th>
                          <th>Ambiente</th>
                          <th>Usuario</th>
                          <th>Programa</th>
                          <th>Lado</th>
                          <th>Archivo</th>
                          <th>Función</th>
                          <th>Descripción</th>
                          <th>Fecha</th>
                          <th>Hora</th>
                          <th>Tipo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datos.map((dato, llave) => (
                          <tr key={llave} className={`align-middle ${(dato.tipo === 0 ? 'text-success' : 'text-danger')}`}>
                            <td className='text-center'>
                              <Button variant={(dato.tipo === 0 ? 'outline-success' : 'outline-danger')} onClick={() => {
                                atenderCaso(dato.caso)
                              }}>
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                              </Button>
                              <Button variant={(dato.tipo === 0 ? 'outline-success' : 'outline-danger')} onClick={() => {
                                verDetalle(dato.detalle)
                              }}>
                                <FontAwesomeIcon icon={faEye} />
                              </Button>
                            </td>
                            <td className='text-end'>{dato.caso}</td>
                            <td className='text-start'>{(dato.ambiente === undefined ? 'Sin definir' : dato.ambiente)}</td>
                            <td className='text-start'>{dato.usuario}</td>
                            <td className='text-start'>{dato.programa}</td>
                            <td className='text-start'>{dato.lado}</td>
                            <td className='text-start'>{dato.archivo}</td>
                            <td className='text-start'>{dato.funcion}</td>
                            <td className='text-start'>{dato.descripcion}</td>
                            <td className='text-end'>{obtenerFechaDeTiempo(dato.tiempo)}</td>
                            <td className='text-end'>{obtenerHoraDeTiempo(dato.tiempo)}</td>
                            <td className='text-center'>{<FontAwesomeIcon icon={(dato.tipo === 0 ? faCircleInfo : faBug)} />}{(dato.tipo === 0 ? 'Info' : 'Error')}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      <Modal show={modal} onHide={cerrarModal} backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faEye} />Detalle
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={cerrarModal}>
            <FontAwesomeIcon icon={faCheck} /> Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Visor;
