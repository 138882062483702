import moment from 'moment';

export function obtenerFechaDeTiempo(tiempo) {
    let envio = moment(tiempo).format("DD-MM-YYYY");
    return envio
}

export function obtenerHoraDeTiempo(tiempo) {
    let envio = moment(tiempo).format("HH:mm:ss");
    return envio
}