import {
    Spinner
} from 'react-bootstrap'

export function Cargador(props) {
    if (props.estado === false) {
        return (
            <>

            </>
        );

    } else {
        return (
            <>
                <Spinner animation="border" role="status" variant='primary'>
                    <span className="visually-hidden">Cargando...</span>
                </Spinner>
            </>
        );
    }
}