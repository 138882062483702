import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './estilo/General.css';
import Visor from './paginas/visor';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' index element={<Navigate to='/1/10' />} />
        <Route path='/:pagina/:intervalo' index element={<Visor />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
